.list-options-container {
    padding: .6rem!important;
    background-color: rgb(122, 122, 122);
}

.list-options-header {
    color: white;
    font-size: .75rem;
    padding: 0;
    margin: 0;
}

.list-options-button-holder {
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
}