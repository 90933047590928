.list-option {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: .75rem;
    border-radius: 4px;
    background-color: white;
    padding: .5rem;
    outline: none;
    border: 0;
    cursor: pointer;
    font: inherit;
    font-weight: 400;
    font-size: .8rem;
}

.list-option:hover {
    -webkit-box-shadow: inset 0px 0px 5px #a5a5a5;
       -moz-box-shadow: inset 0px 0px 5px #a5a5a5;
            box-shadow: inset 0px 0px 5px #a5a5a5;
}

.enabled {
    background: #e5e5e5;
    -webkit-box-shadow: inset 0px 0px 5px #c1c1c1;
       -moz-box-shadow: inset 0px 0px 5px #c1c1c1;
            box-shadow: inset 0px 0px 5px #c1c1c1;
    outline: none;
}

.disabled {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    outline: none;
}

.icon-enabled {
    font-size: .5rem;
    margin-right: .5rem;
    color: #7367f0;
}

.icon-disabled {
    font-size: .5rem;
    margin-right: .5rem;
    color: rgb(190, 36, 36);
}
