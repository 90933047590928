.tracker {
    padding: .6rem;
    margin: 0 auto;
    max-width: 600px;
    max-height: 600px;
    background-color: #283046;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr min-content;
    gap: 1rem;
    align-self: center;
    justify-self: center;
}

