.App {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: min-content 1fr;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.user-not-logged-in-container {
  display: flex;
  justify-content: center;
  align-content: center;
  background-color: #283046;
  border-radius: 8px;
  margin: 2rem;
  padding: 3rem;
}