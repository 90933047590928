.list-output-container {
    grid-column: 1/3;
    padding: .6rem;
    background-color: rgb(122, 122, 122);
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.list-output-header {
    color: white;
    font-size: .8rem;
    padding: 0;
    margin: 0;
}

.list-output-container textarea {
    width: 100%;
    resize: none;
    border-color: rgba(128, 128, 128, 0.432);
    outline: 0;
    border-radius: 4px;
    font: inherit;
    font-size: .8rem;
    font-weight: 400;
}

.list-option {
    background-color: white;
}