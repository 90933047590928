.list-input-container {
    padding: .6rem;
    background-color: rgb(122, 122, 122);
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.list-input-header {
    color: white;
    font-size: .8rem;
    padding: 0;
    margin: 0;
}

.list-input-container textarea {
    height: 100%;
    width: 100%;
    resize: none;
    border-color: rgba(128, 128, 128, 0.432);
    outline: 0;
    border-radius: 4px;
    font: inherit;
    font-weight: 400;
    font-size: .8rem;
}