.menu-option {
    color: white;
    text-decoration: none;
    display: flex;
    align-content: center;
    justify-content: flex-start;
    padding: .7rem 
}

.menu-item-text{
    padding: 0;
    margin: 0;
    font-size: .9rem;
    height: min-content
}

.menu-option:hover{
    cursor:pointer;
    background-color: rgb(39, 39, 39);
}

.menu-icon {
    font-size: 1.3rem;
    margin-right: 3px;
}

.menu-option button{
    display: flex;
    align-content: center;
    color: white;
    width: 100%;
    height: 100%;
    text-align: left;
    margin: 0;
    font: inherit;
    font-weight: inherit;
    font-size: .9rem;
    padding: 0;
}

.login-icon{
    position: relative;
    font-size: 1.3rem;
    margin-right: 3px;
    margin-left: 0;
}
.menu-option button:hover{
    cursor: pointer
}