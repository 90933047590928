@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
.App_App__16ZpL {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: -webkit-min-content 1fr;
  grid-template-columns: min-content 1fr;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.App_user-not-logged-in-container__L0kOS {
  display: flex;
  justify-content: center;
  align-content: center;
  background-color: #283046;
  border-radius: 8px;
  margin: 2rem;
  padding: 3rem;
}
.side-menu {
    background-color: #283046;
    height: 100%;
    width: 200px;
    display: flex;
    flex-direction: column;
    color: white;
}

.brand-title {
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 1rem;
}

.brand-title-logo {
    position: relative;
    font-size: 1.2rem;
    margin-left: 5px;
    top: 3px;
}

.brand-title p{
    font-size: 1.2rem;
    margin: 0;
    padding: 0;
}
.menu-option {
    color: white;
    text-decoration: none;
    display: flex;
    align-content: center;
    justify-content: flex-start;
    padding: .7rem 
}

.menu-item-text{
    padding: 0;
    margin: 0;
    font-size: .9rem;
    height: -webkit-min-content;
    height: min-content
}

.menu-option:hover{
    cursor:pointer;
    background-color: rgb(39, 39, 39);
}

.menu-icon {
    font-size: 1.3rem;
    margin-right: 3px;
}

.menu-option button{
    display: flex;
    align-content: center;
    color: white;
    width: 100%;
    height: 100%;
    text-align: left;
    margin: 0;
    font: inherit;
    font-weight: inherit;
    font-size: .9rem;
    padding: 0;
}

.login-icon{
    position: relative;
    font-size: 1.3rem;
    margin-right: 3px;
    margin-left: 0;
}
.menu-option button:hover{
    cursor: pointer
}
.list-options-container {
    padding: .6rem!important;
    background-color: rgb(122, 122, 122);
}

.list-options-header {
    color: white;
    font-size: .75rem;
    padding: 0;
    margin: 0;
}

.list-options-button-holder {
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
}
.card {
    padding: 0;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.list-option {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: .75rem;
    border-radius: 4px;
    background-color: white;
    padding: .5rem;
    outline: none;
    border: 0;
    cursor: pointer;
    font: inherit;
    font-weight: 400;
    font-size: .8rem;
}

.list-option:hover {
    box-shadow: inset 0px 0px 5px #a5a5a5;
}

.enabled {
    background: #e5e5e5;
    box-shadow: inset 0px 0px 5px #c1c1c1;
    outline: none;
}

.disabled {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    outline: none;
}

.icon-enabled {
    font-size: .5rem;
    margin-right: .5rem;
    color: #7367f0;
}

.icon-disabled {
    font-size: .5rem;
    margin-right: .5rem;
    color: rgb(190, 36, 36);
}

.list-input-container {
    padding: .6rem;
    background-color: rgb(122, 122, 122);
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.list-input-header {
    color: white;
    font-size: .8rem;
    padding: 0;
    margin: 0;
}

.list-input-container textarea {
    height: 100%;
    width: 100%;
    resize: none;
    border-color: rgba(128, 128, 128, 0.432);
    outline: 0;
    border-radius: 4px;
    font: inherit;
    font-weight: 400;
    font-size: .8rem;
}
.list-output-container {
    grid-column: 1/3;
    padding: .6rem;
    background-color: rgb(122, 122, 122);
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.list-output-header {
    color: white;
    font-size: .8rem;
    padding: 0;
    margin: 0;
}

.list-output-container textarea {
    width: 100%;
    resize: none;
    border-color: rgba(128, 128, 128, 0.432);
    outline: 0;
    border-radius: 4px;
    font: inherit;
    font-size: .8rem;
    font-weight: 400;
}

.list-option {
    background-color: white;
}
.tracker {
    padding: .6rem;
    margin: 0 auto;
    max-width: 600px;
    max-height: 600px;
    background-color: #283046;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr -webkit-min-content;
    grid-template-rows: 1fr min-content;
    grid-gap: 1rem;
    gap: 1rem;
    align-self: center;
    justify-self: center;
}


* {
  box-sizing: border-box;
}

#root {
  height: 100%;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
}

body {
  background-color: #161d31;
}

button {
  background: transparent;
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
}

button:hover {
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
}

button:active {
  outline: none;
  border: none;
}

button:focus {
  outline: 0;
}
