.side-menu {
    background-color: #283046;
    height: 100%;
    width: 200px;
    display: flex;
    flex-direction: column;
    color: white;
}

.brand-title {
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 1rem;
}

.brand-title-logo {
    position: relative;
    font-size: 1.2rem;
    margin-left: 5px;
    top: 3px;
}

.brand-title p{
    font-size: 1.2rem;
    margin: 0;
    padding: 0;
}